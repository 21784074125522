import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import axios from 'axios'
import VueAxios from 'vue-axios'
import Vant from 'vant';
import 'vant/lib/index.css';

import '@/utils/kh_theme.css'
Vue.prototype.$app = 'kh' //康辉

// import '@/utils/dy_theme.css'
// Vue.prototype.$app = 'dy' //大运


Vue.use(ElementUI);
Vue.use(VueAxios, axios)
Vue.use(Vant);

Vue.config.productionTip = false

new Vue({
	router,
	store,
	render: h => h(App)
}).$mount('#app')